import {Injectable} from '@angular/core';
import Tracker from "@openreplay/tracker";
import EventNames = Gtag.EventNames;
import ControlParams = Gtag.ControlParams;
import EventParams = Gtag.EventParams;
import CustomParams = Gtag.CustomParams;

export const tracker = new Tracker({
    projectKey: "WXqpndVCe8L5cpYnwRTa",
    ingestPoint: "https://openreplay.hassaly.com/ingest",
    network: {
        capturePayload: true,
        captureInIframes: true
    }
} as any);

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    trackEvent(eventType: EventNames | (string & {}), attributes?: ControlParams | EventParams | CustomParams) {
        try {
            gtag('event', eventType, attributes)
            tracker.event(eventType, attributes, (attributes as any)?.error != null)
        } catch {
        }
    }
}
