import {createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {Nullable} from '../../common/types';
import {User} from '../../models/User';
import * as userActions from './user.actions';
import {tracker} from "../../services/analytics.service";

export interface UserState {
    loginAttempted: boolean;
    user: Nullable<User>;
}

export const initialState = {
    loginAttempted: false,
    user: null,
};

export const userReducer = createReducer<UserState>(
    initialState,
    on(userActions.loginAttempted, state => ({
        ...state,
        loginAttempted: true,
    })),
    on(userActions.updateUser, (state, action) => {
        tracker.setUserID(action.user.email);
        tracker.setMetadata('name', action.user.name);

        return {
            ...state,
            user: action.user,
        }
    })
);

export const userState = createFeatureSelector<UserState>('user');

export const loggedInUser = createSelector(userState, (state: UserState): Nullable<User> => state.user)
export const isLoggedIn = createSelector(
    loggedInUser,
    (user: Nullable<User>): boolean => user != null && user.email != null
);
